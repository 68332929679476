// react/gatsby imports
import React from "react";
// components
import Layout from "components/layout";
import LegalDocument from "components/pages/legal-document";
import SEO from "components/layout/seo";
// assets
import ogImage from "assets/images/seo/index-main.jpg";
import ogImageVK from "assets/images/seo/vk/index-main.jpg";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";
// hooks
import usePublicOfferQuery from "hooks/graphql/usePublicOfferQuery";

const PublicOfferPage = () => {
  const prismicData = usePublicOfferQuery();
  const mainText =
    prismicData.prismicLegaldocument.data.legaldocumentdescription.richText;
  const { data: seoData } = prismicData.page || {};
  const date = prismicData.prismicLegaldocument.data.legaldocumentdate.text;
  const docType = prismicData.prismicLegaldocument.data.legaldocumenttype;
  const additionalInfo =
    prismicData.prismicLegaldocument.data.legaldocadditionalinfo.richText;
  return (
    <Layout>
      <SEO
        title="Пользовательское соглашение"
        url={generateCanonicalLink(PAGE_SLUGS.publicOffer)}
        description={seoData?.seodescription?.text}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
      />
      <div className="section-top-block" />
      <LegalDocument
        additionalInfo={additionalInfo}
        type={docType}
        mainText={mainText}
        date={date}
      />
    </Layout>
  );
};

export default PublicOfferPage;
