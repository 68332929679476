import styled from "styled-components";
import { Link } from "gatsby";
import ArrowBack from "assets/svgs/header-arrow-back.svg";

export const Section = styled.section``;

export const Wrapper = styled.div`
  background: white;
  border-radius: 0.6vw;
  @media only screen and (max-width: 575px) {
    border-radius: 2.5vw;
  }
`;
export const LegalDocumentLeftBlock = styled.div`
  width: 29%;
  @media only screen and (max-width: 575px) {
    display: none;
  }
`;
export const LegalDocumentRightBlock = styled.div`
  width: 71%;
  position: relative;
  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const LegalDocumentInfoBlock = styled.div`
  padding-top: 1.2vw;
`;
export const LegalDocumentInfoBlockText = styled.div`
  line-height: 1.3;
  p {
    font-size: 0.8vw;
    color: var(--granich-grey);
  }
`;
export const LegalDocumentInfoBlockTextMobile = styled.div`
  display: none;
  line-height: 1.3;
  p {
    font-size: 0.8vw;
    color: var(--granich-grey);
  }
  @media only screen and (max-width: 575px) {
    display: block;
    p {
      font-size: 2.5vw;
    }
    margin-bottom: 3vw;
  }
`;

export const LegalDocumentTitle = styled.h1`
  font-size: 5.6vw;
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 500;
  letter-spacing: -0.16vw;
  line-height: 1;
  margin-bottom: 6.3vw;
  @media only screen and (max-width: 575px) {
    font-size: 8.5vw;
    line-height: 0.9;
    margin-bottom: 4.5vw;
  }
`;
export const LegalDocumentText = styled.div`
  font-size: 1.14vw;
  line-height: 1.5;
  margin-left: 0.4vw;
  hr {
    opacity: 0;
    margin: 1vw 0;
  }
  a {
    border-bottom: 1px solid var(--granich-light-grey);
    :hover {
      border-bottom: none;
    }
  }
  ul {
    li {
      padding-left: 1.5vw;
      color: var(--granich-grey);
      i {
        left: -21.1vw;
      }
    }
  }
  p {
    margin-bottom: 0.5vw;
    color: var(--granich-grey);
    position: relative;
  }
  b,
  strong {
    font-weight: 500;
    color: var(--granich-black);
  }
  em {
    position: absolute;
    top: 0;
    left: -19.6vw;
    width: 19vw;
    color: var(--granich-red);
    font-style: normal;
    font-weight: 500;
    font-size: 1.05vw;
  }
  blockquote {
    p {
      margin: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    font-size: 3.7vw;
    margin-left: 0;

    hr {
      margin: 2vw 0;
    }

    ul {
      li {
        padding-left: 3.5vw;

        i {
          left: 0;
        }
      }
    }
    p {
      margin-bottom: 1.5vw;
      letter-spacing: 0;
    }

    em {
      position: relative;
      left: 0;
      width: 100%;
      padding: 2vw 3vw;
      border-radius: 1.5vw;
      margin: 1vw 0;
      display: block;
      color: var(--granich-red);
      font-size: 3.7vw;
    }
    blockquote {
      p {
        margin: 0;
      }
    }
  }
`;
export const LegalDocumentMainContainer = styled.div`
  display: flex;
  padding: 4.2vw 4.8vw 5vw;
  border-bottom: 0.3vw dotted #f2f2f2;
  @media only screen and (max-width: 575px) {
    padding: 6vw 6vw 6vw;
    border-bottom: 0.8vw dotted #f2f2f2;
  }
`;
export const LegalDocumentFooterContainer = styled.div`
  padding: 3vw 4.8vw 5vw;
  @media only screen and (max-width: 575px) {
    padding: 6vw 6vw 8vw;
  }
`;

export const LegalDocumentFooterWrapper = styled.div`
  background: #f2f2f2;
  border-radius: 0.5vw;
  font-family: EB Garamond;
  font-style: italic;
  font-size: 2.37vw;
  font-weight: 500;
  text-align: center;
  padding: 1.4vw;
  letter-spacing: -0.02vw;
  line-height: 1;
  @media only screen and (max-width: 575px) {
    font-size: 6.6vw;
    padding: 4vw 6vw 6vw 7.5vw;
    line-height: 1.1;
    border-radius: 1.5vw;
    text-align: left;
  }
`;
export const LegalDocumentFooterLink = styled(Link)`
  font-style: italic;
  font-family: EB Garamond;
  font-size: 2.37vw;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
  position: relative;
  :before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.5vw;
    width: 100%;
    height: 1px;
    background: #c0c0c0;
  }
  :hover {
    :before {
      display: none;
    }
  }
  @media only screen and (max-width: 575px) {
    font-size: 6.6vw;
    :before {
      bottom: -2vw;
    }
  }
`;

export const LegalDocumentDate = styled.div`
  font-size: 0.9vw;
  font-weight: 500;
  margin-left: 0.4vw;
  color: var(--granich-red);
  padding-top: 1.7vw;
  margin-bottom: 1.2vw;
  @media only screen and (max-width: 575px) {
    font-size: 2.5vw;
    margin-bottom: 5vw;
  }
`;

export const ArrowLinkBack = styled(ArrowBack)`
  position: absolute;
  top: -0.1vw;
  right: -1.4vw;
  width: 1vw;
  height: 1vw;
  @media only screen and (max-width: 575px) {
    top: 0;
    right: -4.7vw;
    width: 3.1vw;
    height: 3.1vw;
  }
`;
