import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Container } from "components/style";
import * as SC from "./style";

const LegalDocument = ({ date, type, mainText, additionalInfo }) => (
  <SC.Section>
    <Container>
      <SC.Wrapper>
        <SC.LegalDocumentMainContainer>
          <SC.LegalDocumentLeftBlock>
            <SC.LegalDocumentInfoBlock>
              <SC.LegalDocumentInfoBlockText>
                <PrismicRichText field={additionalInfo} />
              </SC.LegalDocumentInfoBlockText>
            </SC.LegalDocumentInfoBlock>
          </SC.LegalDocumentLeftBlock>
          <SC.LegalDocumentRightBlock>
            <SC.LegalDocumentDate>{date}</SC.LegalDocumentDate>
            <SC.LegalDocumentTitle>{type}</SC.LegalDocumentTitle>
            <SC.LegalDocumentInfoBlockTextMobile>
              <PrismicRichText field={additionalInfo} />
            </SC.LegalDocumentInfoBlockTextMobile>
            <SC.LegalDocumentText>
              <PrismicRichText field={mainText} />
            </SC.LegalDocumentText>
          </SC.LegalDocumentRightBlock>
        </SC.LegalDocumentMainContainer>
        <SC.LegalDocumentFooterContainer>
          <SC.LegalDocumentFooterWrapper>
            Приятного обучения и до встречи на{" "}
            <SC.LegalDocumentFooterLink to="/#courses">
              Курсах!
              <SC.ArrowLinkBack />
            </SC.LegalDocumentFooterLink>
          </SC.LegalDocumentFooterWrapper>
        </SC.LegalDocumentFooterContainer>
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default LegalDocument;
