const PAGE_SLUGS = {
  foundationOfGraphicDesign: "foundation-of-graphic-design",
  granichFigma: "granich-figma",
  granichInDesign: "granich-indesign",
  granichMidjourney: "granich-midjourney",
  graphicDesignByHand: "graphic-design-by-hand",
  graphicDesignCopywriting: "graphic-design-copywriting",
  graphicMetaphors: "graphic-metaphors",
  graphicSystemSwiss: "graphic-system-swiss",
  privacy: "privacy",
  publicOffer: "public-offer",
  graphicSystemVHS: "vhs",
  graphicSystemAiryLayout: "airy-layout",
  graphicSystemNeoGeo: "neo-geo",
  graphicSystemTechno: "techno",
  graphicSystemConstructivism: "constructivism",
  graphicSystemCollage: "collage",
  graphicSystemExpressism: "expressism",
  graphicSystemNeoslav: "neoslav",
  graphicSystemUI: "ui",
  graphicSystemBrutalism: "brutalism",
  graphicSystemCyberpunk: "cyberpunk",
  artDirecteach: "art-directeach",
};

export default PAGE_SLUGS;
